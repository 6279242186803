/*Body */
body {
  margin: 0;
  padding: 0;
  /* min-height: 100vh; ビューポートの高さいっぱいに最低限表示 */
  display: flex;
  flex-direction: column; /* 子要素を縦に配置 */
}

/*header */
/* Header.js内のCSS */

/*header */
header {
  z-index: 2;
  position: fixed; /* ヘッダーを固定位置に */
  top: 0; /* 上端に固定 */
  left: 0; /* 左端に固定 */
  display: flex;
  width: 100%;
  height: 60px;
  background-color: white;
  align-items: center;
  font-family: Verdana;
  font-weight: lighter;
  font-size: 13px;
}

.header-image{
  padding-left: 5px;
  width: 60px;
  height: 60px;
}
/* .header-item のスタイルを調整 */
.header-item {
  color: black;
  font-family: Verdana;
  font-weight: lighter;
  font-size: 14px; /* フォントサイズを調整（適切なサイズに変更） */
  white-space: nowrap; /* テキストを折り返さないようにする */
  overflow: hidden; /* テキストがはみ出た場合に隠す */
  text-overflow: ellipsis; /* テキストがはみ出た場合に省略記号を表示 */
  font-family: fantasy;
}

.link-line-delete{
  text-decoration: none;
  color:white;
}

a.link-line-delete_2{
  text-decoration: none;
  color:black;
}

/* .nav-header のスタイルを調整 */
.nav-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 30px; /* 右側に20pxのスペースを持たせる（適切なサイズに調整） */
}

.menu-group-header {
  display: flex; /* 子要素を横並びに配置 */
  gap: 10px; /* 子要素間のスペースを調整 */
}

.menu-item-header {
  list-style: none;
  display: inline-block;
  padding: 10px;
}

.normal-menu {
  display: flex; /* 水平方向に広がるように設定 */
  width: 100%;
  justify-content: flex-end; /* 右端に寄せる */
  margin-right: 30px;
}

/*********** /
/*Banner */

.home-page {
  background: url(/images/home.jpg) center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
  position: relative;
}

.home-page::before {
  content: "9AG empowers you to delve deep into the enchanting beauty of the Thai language and culture";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0; /* 初期状態では非表示 */
  animation-name: fadeInText;
  animation-duration: 2s; /* アニメーションの長さを調整 */
  animation-delay: 1s; /* アニメーションの遅延を設定 */
  animation-fill-mode: forwards;
}

.home-page-JP {
  background: url(/images/home.jpg) center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.home-page-JP::before {
  font-family: "Arial", sans-serif; /* 通常のフォントファミリー */
  content: "タイ語を学んでタイをもっと楽しもう";
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0; /* 初期状態では非表示 */
  animation-name: fadeInText;
  animation-duration: 2s; /* アニメーションの長さを調整 */
  animation-delay: 1s; /* アニメーションの遅延を設定 */
  animation-fill-mode: forwards;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; /* フェードインの最終的な透明度を設定 */
  }
}

.thai-character-page {
  background: url(/images/thai-character3.jpg);
  background-position: center center; /* 画像を中央に配置 */
  height: 550px;
}

.mobile-page {
  background: url(/images/mobile.jpg);
  background-position: center center; /* 画像を中央に配置 */
  height: 550px;
}

.grammar-page {
  background: url(/images/grammar.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.listening-page {
  background: url(/images/listening.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}
.reading-page {
  background: url(/images/reading.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.tones-page {
  background: url(/images/tones.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.words-page {
  background: url(/images/words.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}
.user-page {
  background: url(/images/user.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}
.register-page {
  background: url(/images/user2.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.privacypolicy-page {
  background: url(/images/privacypolicy.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.ninearrowsgeeks-page {
  background: url(/images/nine.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.contact-page {
  background: url(/images/contact.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.dictionary-page {
  background: url(/images/index.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  width: 100%;
  height: 550px;
}

.index-page-content {
  font-size: 40px;
  color: black;
  width: auto;
  margin-left: 60px;
  margin-right: 60px;
  height: 550px;
}

.index-page-content h1 {
  padding: 0;
  font-size: 40px;
}

.index-page-content p {
  padding: 0;
  height: 5px;
  font-size: 15px;
}

/*******************************************/
h1 {
  font-family: 'Open Sans', sans-serif; /* 選択したフォントを指定 */
  font-size: 24px; /* 適切なフォントサイズに調整 */
}

h1.title{
  display: flex;
  justify-content: center;
  font-size: 30px;
  /* width: 100%; */
}



/* ページ全体をセンタリングするスタイル */
.center-content {
  display: flex;
  flex-wrap: wrap; /* カードが横に並んでスペース不足の場合に下に移動 */
  justify-content: center;
  margin-top: 15px;
  margin-left: 10%;
  margin-right: 10%;
}

.center-content2 {
  padding-top: 55px; /* ヘッダーの高さに合わせて適切な値を設定 */
}

.center-content3 {
  padding-top: 80px; /* ヘッダーの高さに合わせて適切な値を設定 */
  display: flex; /* フレックスコンテナとして要素を配置 */
  justify-content: center; /* 水平方向にセンター揃え */  
}

/*******************************************/
/* Card Design */
.l-wrapper_06 {
  flex: 0 0 calc(33.33% - 40px); /* カードの幅を33.33%に設定（3つ横並びにするために計算） */
  margin: 20px; /* カード間のマージンを設定 */
  height: auto; /* カードの高さを自動調整 */
}

.l-wrapper_06:hover {
  transform: translateY(-5px);
  box-shadow: 0 7px 34px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
  transition: all .5s;
  height: 100%; /* カードの高さを画像の高さに合わせる */
}
.card_06 {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px #ccc;
}

.card-img_06 {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover; /* 画像が歪まずにカードに収まるようにする */
}

.card-img_06_2 {
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: auto; /* 高さを自動調整 */
  max-width: 100%; /* 幅を100%に制限 */
  max-height: 100%; /* 高さを100%に制限 */
  object-fit: contain; /* 画像を歪まずに収める */
}

.card-content_06 {
  padding: 8px 20px 20px;
}
.card-title_06 {
  font-size: 1.6em;
  color: #333;
  font-weight: 700;
}
.card-text_06 {
  color: #777;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.2em;
}
.card-link_06 {
  text-align: center;
  border-top: 1px solid #eee;
  padding: 20px;
}
.card-link_06 a {
  text-decoration: none;
  color: #4f96f6;
  margin: 0 10px;
}
.card-link_06 a:hover {
  color: #6bb6ff;
}

.card-content_06 p {
  margin-bottom:0;
}

/*******************************************/
/*2nd card design */

/* App.css や別のスタイルファイルに追加 */
.link-text {
  color: rgb(3, 135, 179); /* 水色に設定 */
}

.home-heading {
  font-family: Verdana;
  font-size: 18px;
  margin-left: 8%;
  margin-top: 10px;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  transform-origin: left bottom;
  text-decoration: underline; /* テキストに下線を追加 */
  text-decoration-skip-ink: none; /* 下線がテキストを適切に覆うように設定 */
  color: #000; /* テキストの色を設定 */
}

.home-heading-index {
  font-family: Verdana;
  font-size: 20px; 
  margin-left: 0%; /* 左側に20%の余白を設定 */
  margin-right: 0%; /* 左側に20%の余白を設定 */
  margin-top: 10px;
  margin-bottom: 0%;
}

.card-container2 {
  display: flex; /* カードを横に配置 */
  gap: 20px; /* カードとカードの間に20pxのスペースを設定 */
}

.card2 {
  width: calc(100%); /* レスポンシブデザインで3つのカードを1行に配置 */
  height: auto;
}
.card2__imgframe_1{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/nine-home.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_2{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/privacypolicy.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_3{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/contact.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_4{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/user.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_5{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/index.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_register{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/user2.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_youtube{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/youtube.png) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_mobile{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/mobile.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}


.card2__textbox{
  width: 100%;
  height: auto;
  padding: 20px 18px;
  background: #ffffff;
  box-sizing: border-box;
}
.card2__textbox > * + *{
  margin-top: 10px;
}
.card2__titletext{
  font-size: 20px;
  font-weight: bold;
  line-height: 125%;
}
.card2__overviewtext{
  font-size: 12px;
  line-height: 150%;
}

.card-skin .card2__imgframe{
  position: relative;
  box-shadow: 0px 4px 4px rgba(0,0,0,.4);
}

/*******************************************/
/*Footer */
.custom-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100%; /* 幅を100%に設定 */
  height: 30px; /* 適切な高さに設定 */
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  font-family: Verdana;
  font-weight: lighter;
  font-size: 10px;
  z-index: 1000;
}



/*******************************************/
h1.title-each-page{
  display: flex;
  justify-content: center;
  font-size: 30px;
  /* width: 100%; */
}
/* Button */
.button {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0, #e0e0e0); /* 白系のグラデーションの背景色 */
  color: black; /* ボタンのテキスト色 */
  padding: 15px 0; /* ボタンの上下のパディングを大きく調整 */
  width: 200px; /* ボタンの固定幅を設定（適切な幅に調整してください） */
  border: 2px solid #ccc;
  border-radius: 25px; /* ボタンの角丸を設定 */
  font-size: 18px; /* ボタンのフォントサイズを大きく調整 */
  text-align: center; /* ボタン内のテキストを中央寄せに */
  text-decoration: none; /* ボタン内のテキストの下線を消す */
  cursor: pointer;
  transition: background-color 0.3s ease; /* ホバー時の背景色変化をスムーズにするアニメーション */
}

.button:hover {
  background: linear-gradient(to bottom, #9bd7f6, #9de0fd, #0a95f1); /* ホバー時のグラデーションの背景色 */

}

.button-listening {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0, #e0e0e0); /* 白系のグラデーションの背景色 */
  color: black; /* ボタンのテキスト色 */
  padding: 15px 0; /* ボタンの上下のパディングを大きく調整 */
  width: 100%; /* ボタンの固定幅を設定（適切な幅に調整してください） */
  border: 2px solid #ccc;
  border-radius: 25px; /* ボタンの角丸を設定 */
  font-size: 18px; /* ボタンのフォントサイズを大きく調整 */
  text-align: center; /* ボタン内のテキストを中央寄せに */
  text-decoration: none; /* ボタン内のテキストの下線を消す */
  cursor: pointer;
  transition: background-color 0.3s ease; /* ホバー時の背景色変化をスムーズにするアニメーション */
}

.button-listening:hover {
  background: linear-gradient(to bottom, #9bd7f6, #9de0fd, #0a95f1); /* ホバー時のグラデーションの背景色 */

}

/* styles.css などのファイルにスタイルを追加または変更 */
.button-group {
  display: flex; /* 子要素を横並びに配置 */
  justify-content: space-between; /* 子要素を均等に配置 */
  margin-bottom: 20px; /* 各セクション間に余白を追加（適宜調整） */
}

/* 各リンクボタンのスタイル（必要に応じて調整） */
.button-group .button {
  flex: 1; /* 子要素を均等に広げる */
  margin-right: 3%; /* ボタン間に右側の余白を追加（適宜調整） */
  margin-left: 3%; /* ボタン間に右側の余白を追加（適宜調整） */
}

.button-group-home {
  display: flex; /* 子要素を横並びに配置 */
  justify-content: space-between; /* 子要素を均等に配置 */
  justify-content: center;
  margin-bottom: 20px; /* 各セクション間に余白を追加（適宜調整） */
}

.options-section button {
  display: block; /* ボタンをブロック要素として表示 */
  margin: 5px 0; /* ボタン間の垂直マージンを追加 */
  text-align: left; /* テキストを中央揃えに */
  font-size: 20px;
  /* border-radius: 100vh; */
  /* min-width: 150px; */
}

.quiz-app-parent {
  height: auto;
  overflow: hidden; /* スクロールバーを非表示にする */
}

.tone-button {
  display: flex;
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  /* その他のスタイルプロパティを追加できます */
}

/*******************************************/
/* Logo */
.logo {
  text-align: center; /* テキストを中央に配置 */
}

.logo img {
  width: 200px; /* ロゴの幅 */
  height: 200px; /* ロゴの高さ */
}

/* mobile意外でSpaナビ消す */
.sp-nav {
  display: none;
  }

  .input-field {
    margin-bottom: 20px; /* 各入力フィールドの下に余白を設定 */
  }
  
  .input-field label {
    display: block; /* ラベルをブロック要素として表示 */
    margin-bottom: 5px; /* ラベルの下に余白を設定 */
    font-weight: bold; /* ラベルのテキストを太字に設定 */
  }
  
  .input-container {
    display: flex;
    flex-direction: column; /* 子要素を垂直に並べるためにカラム方向に設定 */
    justify-content: center; /* 垂直方向に中央揃え */
    align-items: center; /* 水平方向に中央揃え */
  }
  
  .input-field input {
    width: 100%; /* 入力フィールドの幅を70%に設定 */
    padding: 10px; /* 入力フィールドの内側の余白を設定 */
    border: 1px solid #ccc; /* 枠線を設定 */
    border-radius: 5px; /* 枠線の角を丸める */
    font-size: 16px; /* フォントサイズを設定 */
  }
  
  .input-field button {
    background-color: #007bff; /* ボタンの背景色を設定 */
    color: #fff; /* ボタンのテキスト色を設定 */
    border: none; /* ボタンの枠線をなしに設定 */
    padding: 10px 20px; /* ボタンの内側の余白を設定 */
    border-radius: 5px; /* ボタンの角を丸める */
    cursor: pointer; /* カーソルをポインターに変更 */
    font-size: 16px; /* フォントサイズを設定 */
  }
  
  .input-field button:hover {
    background-color: #0056b3; /* ボタンのホバー時の背景色を設定 */
  }
  
  .sententce-container {
    margin-left: 10%;
    margin-right: 10%;
  }


/* テーブル全体に罫線を追加 */
/* テーブル全体に罫線を追加 */
table {
  border-collapse: collapse;
  width: 100%;
}

table th:nth-child(2), table td:nth-child(2) {
  display: table-cell; /* デフォルトでは表示 */
}

/* テーブルのヘッダー行 */
table th {
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
}

/* テーブルのデータ行 */
table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* オッド行とエベン行に色付け */
table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* マウスオーバー時のハイライト */
table tr:hover {
  background-color: #f5f5f5;
}


/***************** media query *******/
/***************** mobile css  *********************************************************************************************/
/************************/

@media screen and (max-width: 768px) {
  .home-page {
    background: url(/images/home.jpg);
    background-repeat: no-repeat;
    font-family: "Helvetica", sans-serif; /* モバイル用のフォントファミリー */
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 300px;
    position: relative;
  }

  .home-page::before {
    content: "9AG empowers you to delve deep into the enchanting beauty of the Thai language and culture";
    position: absolute;
    width: 90%; /* 両端に5%ずつ余白を取る */
    top: 50%;
    left: 5%; /* 左端に5%の余白 */
    transform: translateY(-50%);
    font-size: 20px;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    animation-name: fadeInText;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
  
  .home-page-JP {
    background: url(/images/home.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .home-page-JP::before {
    content: "タイ語を学んでタイをもっと楽しもう";
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0; /* 初期状態では非表示 */
    animation-name: fadeInText;
    animation-duration: 2s; /* アニメーションの長さを調整 */
    animation-delay: 1s; /* アニメーションの遅延を設定 */
    animation-fill-mode: forwards;
  }

  .thai-character-page {
    background: url(/images/thai-character3.jpg);
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  
  .mobile-page {
    background: url(/images/mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .grammar-page {
    background: url(/images/grammar.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .listening-page {
    background: url(/images/listening.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .reading-page {
    background: url(/images/reading.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .tones-page {
    background: url(/images/tones.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  
  .words-page {
    background: url(/images/words.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .user-page {
    background: url(/images/user.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .register-page {
    background: url(/images/user2.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }
  .privacypolicy-page {
    background: url(/images/privacypolicy.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .ninearrowsgeeks-page {
    background: url(/images/nine.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .contact-page {
    background: url(/images/contact.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .dictionary-page {
    background: url(/images/index.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
    background-position: center center; /* 画像を中央に配置 */
    width: 100%;
    height: 300px;
  }

  .center-content2 {
    padding-top: 0px; /* ヘッダーの高さに合わせて適切な値を設定 */
  }
  h1.title{
    
    border: 0;
    font-size: 10px;
    margin-bottom: 0px;
    margin-top: 10px;
    /* width: 100%; */
  }  

/* ヘッダー全体 */
header {
  display: flex;
  width: 100%;
  height: 38px;
  background-color: white;
  align-items: center;
  font-family: Verdana;
  font-weight: lighter;
  font-size: 20px;
  position: relative; 
}

/* ヘッダー内のアイテム */
.header-image {
  padding-left: 5px;
  width: 40px;
  height: 40px;
}

.header-item{
  font-size:12px;
}

.header-item-hamburger{
  font-size:13px;
  color: white;
}

#bm-burger-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px; /* 必要に応じて幅を調整 */
  height: 100%; /* ヘッダーと同じ高さに設定 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bm-burger-button {
  position: absolute;
  top: 0;
  right: 0;
  left: initial; /* 左寄せを解除 */
  width: 50px; /* 必要に応じて幅を調整 */
  height: 100%; /* ヘッダーと同じ高さに設定 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover; /* 画像を領域に合わせて拡大・縮小 */
  background-position: center center; /* 画像を中央に配置 */
  z-index: 1000;
}

.bm-burger-bars {
  background:#EEEEEE; /* 背景色を指定 */
  width: 70%; /* 線の幅を設定 */
  margin-left: auto; /* 右寄せにする */
}


.menu-item-header{
  font-size: 10px;
}

.hamburger-aa{
  background: black;
}

.bm-item{
  padding: 15px;
}

.bm-overlay{
  background: rgba(0,0,0,0.8);
  z-index: 1000; /* 他の要素よりも大きな値を指定 */
}

/* Mobile Card Design */

.center-content {
  display: flex;
  flex-direction: column; /* カードを縦に配置 */
  align-items: center; /* カードを中央揃え */
}

.l-wrapper_06 {
  margin: 1rem 0; /* カード間の上下マージン */
  width: 100%; /* カードの幅を100%に設定 */
  max-width: 400px; /* カードの最大幅を設定（適切な幅に調整） */
  box-sizing: border-box; /* ボックスモデルを調整 */
}

.center-content3 {
  padding-top: 15px; /* ヘッダーの高さに合わせて適切な値を設定 */
}

.sententce-container {
  margin-left: 2%;
  margin-right: 2%;
}

.sententce-container-index-page {
  margin-left: 0.5px;
  margin-right: 0.5px;
}


/*******************************************/
/*2nd card design */

.link-text {
  color: rgb(3, 135, 179); /* 水色に設定 */
}

.home-heading {
  font-family: Verdana;
  font-size: 15px;
  margin-left: 3%; /* 左側に20%の余白を設定 */
  margin-top: 10px;
  margin-bottom: 0%;
}

.card-container2 {
  display: flex; /* カードを横に配置 */
  flex-wrap: wrap; /* レスポンシブデザインのためにカードが横に並び切らないように */
  gap: 20px; /* カードとカードの間に20pxのスペースを設定 */
  justify-content: space-between; /* カードを等間隔で配置 */
}

.card2 {
  width: calc(100%); /* レスポンシブデザインで3つのカードを1行に配置 */
  height: auto;
}
.card2__imgframe_1{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/nine-home.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_2{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/privacypolicy.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_3{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/contact.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_4{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/user.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}
.card2__imgframe_5{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/index.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_register{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/user2.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_youtube{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/youtube.png) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}

.card2__imgframe_mobile{
  width: 100%;
  height: auto;
  padding-top: 56.25%;
  background: url(/images/mobile.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
}


.card2__textbox{
  width: 100%;
  height: auto;
  padding: 20px 18px;
  background: #ffffff;
  box-sizing: border-box;
}
.card2__textbox > * + *{
  margin-top: 10px;
}
.card2__titletext{
  font-size: 20px;
  font-weight: bold;
  line-height: 125%;
}
.card2__overviewtext{
  font-size: 12px;
  line-height: 150%;
}

.card-skin .card2__imgframe{
  position: relative;
  box-shadow: 0px 4px 4px rgba(0,0,0,.4);
}


/* テーブル全体に罫線を追加 */
table {
  border-collapse: collapse;
  width: 100%;
}

/* モバイルビュー用スタイル */
@media (max-width: 768px) { /* 例: 768px以下の画面幅がモバイルビュー */
  table th:nth-child(2), table td:nth-child(2) {
    display: none; /* 非表示にする */
  }
}

/* テーブルのヘッダー行 */
table th {
  font-size: 10px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
}

/* テーブルのデータ行 */
table td {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* オッド行とエベン行に色付け */
table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* マウスオーバー時のハイライト */
table tr:hover {
  background-color: #f5f5f5;
}


/*log in filed */


/* media query,mobile finish tag */




}
